import React from 'react';
import Swal from 'sweetalert2';

export const showSweetAlert = (message, name, iconUrl) => {
    return Swal.fire({
        title: message,
        imageUrl: iconUrl,
        showConfirmButton: false,
        timer: 1000, // Auto-close after 1s
        customClass: {
          popup: name
        }
    });
  };

  export const showLastSweetAlert = (message, name, iconUrl, navigate, navigatePage) => {    
    return Swal.fire({
        title: message,
        imageUrl: iconUrl,
        showConfirmButton: false,
        timer: 1500, // Auto-close after 1s
        customClass: {
          popup: name
        }
    }).then(() => {
        navigate(navigatePage);
    });
  };

const removePunctuation = (text) => text.replace(/[.,?!'"]/g, '');

const numberAndSymbolMapping = {
  '0': ['zéro'],
  '1': ['un', 'une'],
  '2': ['deux'],
  '3': ['trois'],
  '4': ['quatre'],
  '5': ['cinq'],
  '6': ['six'],
  '7': ['sept'],
  '8': ['huit'],
  '9': ['neuf'],
  '10': ['dix'],
  '11': ['onze'],
  '12': ['douze'],
  '13': ['treize'],
  '14': ['quatorze'],
  '15': ['quinze'],
  '16': ['seize'],
  '17': ['dix-sept', 'dix sept'],
  '18': ['dix-huit', 'dix huit'],
  '19': ['dix-neuf', 'dix neuf'],
  '20': ['vingt'],
  '21': ['vingt-et-un', 'vingt et un'],
  '22': ['vingt-deux', 'vingt deux'],
  '23': ['vingt-trois', 'vingt trois'],
  '24': ['vingt-quatre', 'vingt quatre'],
  '25': ['vingt-cinq', 'vingt cinq'],
  '26': ['vingt-six', 'vingt six'],
  '27': ['vingt-sept', 'vingt sept'],
  '28': ['vingt-huit', 'vingt huit'],
  '29': ['vingt-neuf', 'vingt neuf'],
  '30': ['trente'],
  '31': ['trente-et-un', 'trente et un'],
  '32': ['trente-deux', 'trente deux'],
  '33': ['trente-trois', 'trente trois'],
  '34': ['trente-quatre', 'trente quatre'],
  '35': ['trente-cinq', 'trente cinq'],
  '36': ['trente-six', 'trente six'],
  '37': ['trente-sept', 'trente sept'],
  '38': ['trente-huit', 'trente huit'],
  '39': ['trente-neuf', 'trente neuf'],
  '40': ['quarante'],
  '41': ['quarante-et-un', 'quarante et un'],
  '42': ['quarante-deux', 'quarante deux'],
  '43': ['quarante-trois', 'quarante trois'],
  '44': ['quarante-quatre', 'quarante quatre'],
  '45': ['quarante-cinq', 'quarante cinq'],
  '46': ['quarante-six', 'quarante six'],
  '47': ['quarante-sept', 'quarante sept'],
  '48': ['quarante-huit', 'quarante huit'],
  '49': ['quarante-neuf', 'quarante neuf'],
  '50': ['cinquante'],
  '51': ['cinquante-et-un', 'cinquante et un'],
  '52': ['cinquante-deux', 'cinquante deux'],
  '53': ['cinquante-trois', 'cinquante trois'],
  '54': ['cinquante-quatre', 'cinquante quatre'],
  '55': ['cinquante-cinq', 'cinquante cinq'],
  '56': ['cinquante-six', 'cinquante six'],
  '57': ['cinquante-sept', 'cinquante sept'],
  '58': ['cinquante-huit', 'cinquante huit'],
  '59': ['cinquante-neuf', 'cinquante neuf'],
  '60': ['soixante'],
  '61': ['soixante-et-un', 'soixante et un'],
  '62': ['soixante-deux', 'soixante deux'],
  '63': ['soixante-trois', 'soixante trois'],
  '64': ['soixante-quatre', 'soixante quatre'],
  '65': ['soixante-cinq', 'soixante cinq'],
  '66': ['soixante-six', 'soixante six'],
  '67': ['soixante-sept', 'soixante sept'],
  '68': ['soixante-huit', 'soixante huit'],
  '69': ['soixante-neuf', 'soixante neuf'],
  '70': ['soixante-dix', 'soixante dix'],
  '71': ['soixante-et-onze', 'soixante et onze'],
  '72': ['soixante-douze', 'soixante douze'],
  '73': ['soixante-treize', 'soixante treize'],
  '74': ['soixante-quatorze', 'soixante quatorze'],
  '75': ['soixante-quinze', 'soixante quinze'],
  '76': ['soixante-seize', 'soixante seize'],
  '77': ['soixante-dix-sept', 'soixante dix sept'],
  '78': ['soixante-dix-huit', 'soixante dix huit'],
  '79': ['soixante-dix-neuf', 'soixante dix neuf'],
  '80': ['quatre-vingts', 'quatre vingts'],
  '81': ['quatre-vingt-un', 'quatre vingt un'],
  '82': ['quatre-vingt-deux', 'quatre vingt deux'],
  '83': ['quatre-vingt-trois', 'quatre vingt trois'],
  '84': ['quatre-vingt-quatre', 'quatre vingt quatre'],
  '85': ['quatre-vingt-cinq', 'quatre vingt cinq'],
  '86': ['quatre-vingt-six', 'quatre vingt six'],
  '87': ['quatre-vingt-sept', 'quatre vingt sept'],
  '88': ['quatre-vingt-huit', 'quatre vingt huit'],
  '89': ['quatre-vingt-neuf', 'quatre vingt neuf'],
  '90': ['quatre-vingt-dix', 'quatre vingt dix'],
  '91': ['quatre-vingt-onze', 'quatre vingt onze'],
  '92': ['quatre-vingt-douze', 'quatre vingt douze'],
  '93': ['quatre-vingt-treize', 'quatre vingt treize'],
  '94': ['quatre-vingt-quatorze', 'quatre vingt quatorze'],
  '95': ['quatre-vingt-quinze', 'quatre vingt quinze'],
  '96': ['quatre-vingt-seize', 'quatre vingt seize'],
  '97': ['quatre-vingt-dix-sept', 'quatre vingt dix sept'],
  '98': ['quatre-vingt-dix-huit', 'quatre vingt dix huit'],
  '99': ['quatre-vingt-dix-neuf', 'quatre vingt dix neuf'],
  '100': ['cent'],
  '000': ['mille'],
  '500': ['cinq cent', 'cinq cents'],
  '640': ['six cent quarante', 'six cents quarante'],    
  '1345': ['une virgule trois quatre cinq', 'un virgule trois quatre cinq'], // must remove , because of removepunctuation
  '6725': ['six cent soixante douze virgule cinq', 'six cents soixante douze virgule cinq'], // remove ,
  'ns9204i': ['n s neuf deux zéro quatre i'],
  '4352': ['quatre trois cinq deux'],
  '509': ['cinq cent neuf'],
  '%': ['pour cent'],
  '347841': ['trois quatre sept huit quatre un'],
  '684 976 4312': ['six huit quatre neuf sept six quatre trois un deux'], // remove -
  '379 5136 1284': ['trois sept neuf cinq un trois six un deux huit six'],
  '911': ['neuf un un'],
  'st laurent': ['st laurent', 'saint laurent'],
  'st hubert': ['st hubert', 'saint hubert'],
  'évanoui': ['évanoui', 'évanouie'],
  '250': ['deux virgule cinquante'], // 2,50
  '6h': ['six heures', 'six heure'],
  '22h': ['vingt deux heures', 'vingt deux heure'],
  '120': ['cent vingt'],
  '162': ['un six deux', 'cent soixante deux'],
  '2023': ['deux mille vingt trois'],
  '$': ['dollar', 'dollars'], 
};

// Generate both hyphenated and space-separated variants for each mapped number phrase
const expandWordWithMapping = (word) => {
  if (numberAndSymbolMapping[word]) {
    return [word, ...numberAndSymbolMapping[word]];
  }
  return [word];
};

// Create alternative representations for sentenceB by generating possible sequences
const generateAlternativeSet = (text) => {
  const words = text.split(/\s+/);
  const alternatives = new Set();

  // Add the original space-separated phrase
  alternatives.add(words.join(' '));

  // Add the hyphenated version of the words
  alternatives.add(words.join('-'));

  // Add each contiguous phrase sequence
  for (let i = 0; i < words.length; i++) {
    for (let j = i + 1; j <= words.length; j++) {
      alternatives.add(words.slice(i, j).join(' '));
      alternatives.add(words.slice(i, j).join('-'));
    }
  }

  return alternatives;
};

export const highlightMatches = (sentenceB, sentencesA) => {
  if (!sentencesA || !sentenceB) return;

  const wordsA = sentencesA.split(/\s+/);             // Does not convert word to lowercase here to preserve original sentence
  const wordsB = generateAlternativeSet(removePunctuation(sentenceB.replace(/-/g, ' ').toLowerCase()));
  
  return wordsA.map((word, index) => {
      return (
          <span key={index} style={{ color: (acceptableAnswer(wordsB, word.toLowerCase()) || word==='-') ? '#00C667' : '#F40000' }}>
              {word + ' '}
          </span>
      );
  });
};

export const highlightMatchesWord = (sentenceB, sentencesA) => {
  if (!sentencesA || !sentenceB) return;  

  const wordsA = sentencesA.split(/\s+/);             // Does not convert word to lowercase here to preserve original sentence
  const wordsB = generateAlternativeSet(removePunctuation(sentenceB.replace(/-/g, ' ').toLowerCase()));
  
  for (let word of wordsA) {
    if (!acceptableAnswer(wordsB, word.toLowerCase())) {
      return false; // Return false immediately if any word doesn't match
    }
  }
  return true;
};
export const acceptableAnswer = (textB, textA) => {
  if (/^[,\.?!'"]$/.test(textA)){   
    return true;    //if textA is a standalone ? ! , . ' "
  } 

  const textAAlternatives = expandWordWithMapping(removePunctuation(textA.replace(/-/g, ' ')));
  
  // Check if any alternative matches with words in `wordsB`
  return textAAlternatives.some(word => textB.has(word));  
}

export const getMatchPercentage = (textB, textA) => {
  if (!textB.trim()) return 0;
  const wordsB = removePunctuation(textB.toLowerCase()).replace(/-/g, ' ').split(/\s+/);
  const wordsA = removePunctuation(textA.toLowerCase()).replace(/-/g, ' ').split(/\s+/);
  const matches = wordsB.filter(word => wordsA.includes(word));
  return (matches.length / wordsA.length) * 100;
}

export const calculateAverageMatch = (userAnswers) => {  //userAnswers here correspond to one conversation
  if (!userAnswers || !Array.isArray(userAnswers.pairs)) {
    return 0; // Return 0 if userAnswers is not valid or doesn't have a pairs array
  }

  let totalMatch = 0;
  let numPairs = 0;

  userAnswers.pairs.forEach(pair => {
      totalMatch += pair.matchPercentage; // Assume matchPercentage is a number
      numPairs++;
  });

  if (numPairs > 0) {
      return (totalMatch / numPairs);
  }
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getClassName = (vocabObject, selectedPhrase, word) => {  
  // Normalize the word (remove punctuation, etc.)
  let normalizedWord = '';
  if (/\d/.test(word) && word.trim() !== '911,') {// if word contains number
    normalizedWord = word.toLowerCase().replace(/[.?!"]/g, '')
  } else {
    normalizedWord = word.toLowerCase().replace(/[.?,!"]/g, '')
  }
  
  if (word === '?' || word === '!' || word === '"' || word === '.' || word === ',' || word === '-' )  {
      return 'word'; // Return default class if no vocab entry is found
    }

  // If the word is selected, make the color stronger
  let isSelected;
  if(selectedPhrase.includes(' ')){
    isSelected = selectedPhrase.toLowerCase().includes(normalizedWord);
  } else {
    isSelected = selectedPhrase.toLowerCase() === normalizedWord;
  }  
  const selected = isSelected ? 'selected' : '';

  // Return the final class for the word
  return `word ${selected}`;
};

// export const InstructionBubble = ({ message, name }) => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     if (!sessionStorage.getItem(name)) {
//       setIsVisible(true); // If not shown, make it visible
//       sessionStorage.setItem(name, 'true'); // Set a flag in sessionStorage

//       const timer = setTimeout(() => {
//         setIsVisible(false);
//       }, 2000); // The bubble will disappear after 2 seconds

//       return () => clearTimeout(timer); // Clear the timer if the component unmounts
//     }
//   }, []);

//   if (!isVisible) return null;

//   return (
//     <div className={name}>
//       {message}
//     </div>
//   );
// };

export const frToAudio = (fr, theme, topic) => {
  var punctuation = /[.,?!"<>%|/\\*]/g;   //g means remove . , ? ! ' " in whole sentence
  return(`/Audio/${theme}/${topic}/${fr.replace(punctuation, '').trim().substring(0,100)}.mp3`);
}

export const playWordAudio = (phrase, play, englishTopic) => {
  const processPhrase = phrase.trim();
  let path = '/Audio/Dictionary';

  if (!isNaN(processPhrase[0])) {
    // If the first character is a number, use the Numbers folder
    path += '/Numbers';
  } else if (/[A-Za-z]/.test(processPhrase[0])) {
      // If the first character is a letter, use the folder corresponding to that letter
      path += `/${processPhrase[0].toUpperCase()}`;
  } else {
      // For special cases or any other characters, use the main Dictionary folder
      path += '/Others';
  }

  if (processPhrase === "aux"){    //special case aux.mp3 can't exist
    play(`${path}/aaux.mp3`);
  } else if (processPhrase === "à côté de"){    //special case, can't play mp3 if pass through path
    play(`Audio/Dictionary/Others/à côté de.mp3`);
  } else if (processPhrase === "%"){    
    play(`${path}/pour-cent.mp3`);
  } else if((englishTopic === 'Spelling Exercises' || englishTopic === 'Spelling') && processPhrase === 'Y'){
    play(`${path}/Y-letter.mp3`);
  } else {
    play(`${path}/${processPhrase}.mp3`);
  };
}

export const determineTopicNameFromVerbName = (tense_type_fr, tense_name_fr) => {
  //four indicatif tenses that I did not do
//   plus-que-parfait
// passe simple
// passe anterieur
// futur anterieur
  if (tense_type_fr === 'Indicatif' && tense_name_fr === 'Présent') {
    return 'Le présent';
  } else if (tense_type_fr === 'Indicatif' && tense_name_fr === 'Futur proche') {
    return 'Le futur proche';
  } else if (tense_type_fr === 'Indicatif' && tense_name_fr === 'Passé récent') {
    return 'Le passé récent';
  } else if (tense_type_fr === 'Indicatif' && tense_name_fr === 'Futur simple') {
    return 'Le futur simple';
  } else if (tense_type_fr === 'Indicatif' && tense_name_fr === 'Passé composé') {
    return 'Le passé composé';
  } else if (tense_type_fr === 'Indicatif' && tense_name_fr === 'Imparfait') {
    return 'L’imparfait';
  } else if (tense_type_fr === 'Subjonctif') {
    return 'Le subjonctif';
  } else if (tense_type_fr === 'Conditionnel') {
    return 'Le conditionnel';
  } else if (tense_type_fr === 'Impératif') {
    return 'L’impératif';
  } else {
    return `${tense_type_fr} - ${tense_name_fr}`;
  }
};

export const showPartOfSpeech = (part_of_speech_id, selectedLanguage) => {
  switch (part_of_speech_id) {
    case 1:
      return translations[selectedLanguage].Pronoun; 
    case 2:
      return translations[selectedLanguage].Verb; 
    case 3:
      return translations[selectedLanguage].PronominalVerb;
    case 4:
      return translations[selectedLanguage].Adverb; 
    case 5:
      return translations[selectedLanguage].MasculineAdjective; 
    case 6:
      return translations[selectedLanguage].FeminineAdjective; 
    case 7:
      return translations[selectedLanguage].Adjective; 
    case 8:
      return translations[selectedLanguage].MasculineNoun; 
    case 9:
      return translations[selectedLanguage].FeminineNoun; 
    case 10:
      return translations[selectedLanguage].Noun; 
    case 11:
      return translations[selectedLanguage].Preposition; 
    case 12:
      return translations[selectedLanguage].Conjunction; 
    case 13:
      return translations[selectedLanguage].Article; 
    case 14:
      return translations[selectedLanguage].Determiner; 
    case 15:
      return translations[selectedLanguage].Interjection; 
    case 16:
      return translations[selectedLanguage].PossessiveAdjective; 
    case 17:
      return translations[selectedLanguage].Contraction;  
    case 18:
      return translations[selectedLanguage].Phrase;    
    default:
        return translations[selectedLanguage].OutOfRange; 
  }
}

export const determineIpaPopup = (ipa) => {
  try { 
    // let uniqueSounds = new Set();   
    let content = '<div class="ipa-list"> ';      
    const chars = ipa.slice(1, -1).split(''); // Split the IPA into an array of characters
    const normalChars = ['œ', 'y', 'u', 'i', 'ə', 'ø', 'ɛ', 'e', 'o', 'ɔ', 'ɑ', 'a', 't', 'ŋ', 'ɲ', '.', ' ',
      'n', 'm', 'l', 'ɥ', 'w', 'j', 'ʁ', 'ʀ', 'b', 'p', 'd', 't', 'ɡ', 'k', 'v', 'f', 'z', 's', 'ʒ', 'ʃ', 'S', 'Z', 'r', 'G'];

    for (let i = 0; i < chars.length; i++) {
        let char = chars[i];

        // Check if the next character is a special character that should be grouped
        if (i + 1 < chars.length && !normalChars.includes(chars[i + 1])) {
            char += chars[i + 1]; // Group the current char with the next one
            i++; // Skip the next char since it's already grouped
        }        
        
        if (char === 'ɡ'){
          content += `<div class="ipa-item"> ${char}  <button class="listen-button" data-audio="/Audio/IPA/k voiced.mp3"> //rename to 'k voiced.mp3' because can't run g.mp3
              <img src="/Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen}/>
          </button></div>`;
        } else if (char !== '.' && char !== ' '){// && !uniqueSounds.has(char)){
          // uniqueSounds.add(char);
          content += `<div class="ipa-item"> ${char}  <button class="listen-button" data-audio="/Audio/IPA/${char}.mp3">
                        <img src="/Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen}/>
                    </button></div>`;
        } else {
          content += `<br/>`
        }       
    }
    content += ' </div>';
    return content;
  } catch (error) {
    console.error('Error fetching topic content:', error);
  }
};

export const translations = {
  en: {
    next: 'Next',
    back: 'Back',
    start: 'Start',
    summary: 'Summary',
    study: 'Study',    
    Practice: 'Practice',
    PRACTICE: 'PRACTICE',
    pronunciation: 'Pronunciation',
    grammar: 'Grammar',
    chooseATheme: 'Choose a theme',
    chooseATopic: 'Choose a topic',
    HINT: 'HINT', 
    Option: 'Option', 
    TypeAnswerHere: 'Type your answer here...',
    Write: 'Write',
    Speak: 'Speak',
    YouScore: 'You Score',
    Detected: 'Detected',
    Expected: 'Expected',
    BravoYouFinished: 'Bravo! You just finished',
    Theme: 'Theme',
    Topic: 'Topic',
    PronunciationReport: 'Pronunciation Report',
    GrammarReport: 'Grammar Report',
    PracticeAgain: 'Practice again',
    ReviseThisConversation: 'Revise this conversation',
    ChooseAnotherTopic: 'Choose Another Topic',
    DontGiveUp: "Don't give up!",
    YouCanDoIt: "You can do it",
    KeepPracticing: "Keep practicing",
    KeepGoing: "Keep going",
    NotBad: "Not bad",
    GoodJob: "Good job!",
    Bravo: "Bravo!",
    Super: "Super!",
    Excellent: "Excellent!",
    Wow: "Wow!",
    Pro: "What a pro!",
    OutOfRange: "Value is out of range", 
    Menu: 'Menu',
    SearchATopic: 'Search a topic', 
    Home: 'Home', 
    MyVocab: 'My vocabularies', 
    Pronunciation: 'Pronunciation', 
    Grammar: 'Grammar', 
    Profile: 'Profile',
    Search: 'Search',
    collapse: 'collapse',
    expand: 'expand',
    Pause: 'Pause',
    Listen: 'Listen',
    Slow: 'Slow',
    Translation: 'Translation',
    Stop: 'Stop',
    Microphone: 'Microphone',
    Liked: 'Liked',
    Unliked: 'Unliked',
    Logo: 'Logo',
    Transcription: 'Transcription',
    Recording: 'Recording',
    Submit: 'Submit',
    Delete: 'Delete',
    Flag: 'Flag',
    Help: 'Help',
    PleaseChooseATheme: 'Please choose a theme',
    PleaseChooseAGrammarGroup: 'Please choose a grammar group',    
    PleaseChooseAPronunciationGroup: 'Please choose a pronunciation group',
    PracticeSpeaking: 'Practice speaking',
    PracticeWriting: 'Practice writing',
    TypeHere: 'Type here...',    
    Situation: 'Situation',
    change: 'change',
    AI: 'AI',
    User: 'User',
    Audio: 'Audio: ',
    Listentothissentence: 'Listen to this sentence<br/><br/>',
    SlowLabel: 'Slow: ',
    Adjustaudiospeed: 'Adjust audio speed<br/><br/>',
    TranslationLabel: 'Translation: ',
    Seetranslation: 'See translation<br/><br/>',
    MicrophoneLabel: 'Microphone: ',
    Readthissentence: 'Read this sentence and receive instant feedback.<br/><br/>If this button does not work, go to your phone Settings -> your browser -> Microphone -> Ask<br/><br/>',
    Expandablesentence: 'Expandable sentences: ',
    Clickthissentencetolearn: 'Click each sentence to learn<br/><br/>',
    PracticeLabel: 'Practice: ',
    Practicewhenyouareready: 'Practice when you are ready<br/><br/>',
    changeLabel: 'change: ',
    changeSituation: 'Choose another situation<br/><br/>',
    SituationLabel: 'Situation: ',
    situationDescription: 'Show or hide the situation description<br/><br/>',
    TranscriptionLabel: 'Transcription: ',
    Seetranscription: 'See transcription<br/><br/>',
    Options: 'Options: ',
    possibleAnswers: 'These are possible answers.<br/><br/>',
    Recordyouranswers: 'Record your answers<br/><br/>',
    inputarea: 'Typing area: ',
    Writeyouranswers: 'Type your answers<br/><br/>',
    Contraction: 'Contraction',
    Masculine: 'Masculine',
    Feminine: 'Feminine',
    Plural: 'Plural',
    Pronoun: 'Pronoun',
    Verb: 'Verb',
    PronominalVerb: 'Pronominal Verb',
    Adverb: 'Adverb',
    MasculineAdjective: 'Masculine Adjective',
    FeminineAdjective: 'Feminine Adjective',
    Adjective: 'Adjective',
    MasculineNoun: 'Masculine Noun',
    FeminineNoun: 'Feminine Noun',
    Noun: 'Noun',
    Preposition: 'Preposition',
    Conjunction: 'Conjunction',
    Article: 'Article',
    Determiner: 'Determiner',
    Interjection: 'Interjection',
    PossessiveAdjective: 'Possessive Adjective',
    // STOP: 'STOP', 
    studySpeak: 'Study - Speaking',
    studyListen: 'Study - Listening',
    NoviceProgram: 'Novice program',
    GeneralProgramEasy: 'General program - easy',
    GeneralProgram: 'General program',
    Contraction: 'Contraction',
    GrammarTopic: 'Grammar topic: ',
    Note: 'Note: ', 
    Phrase: 'Phrase',
    FeedbackSent: 'Feedback submitted successfully!',
    FeedbackNotSent: 'Failed to submit feedback. Our apology. Please send your feedback to info@aiducare.com',
    SpeakLouder: 'Please speak louder',
    InstructionVideo: 'Instructional video',
    Doesnotsupportvideo: 'Your browser does not support the video tag',
    WordLabel: 'Text: ',
    Clickonword: 'Click on individual words to study',
  },
  fr: {
    next: 'Suivant',
    back: 'Retour',
    start: 'Commencer',
    summary: 'Résumé',
    study: 'Étudier',
    Practice: 'Pratiquer',
    PRACTICE: 'PRATIQUER',
    pronunciation: 'Prononciation',
    grammar: 'Grammaire',
    chooseATheme: 'Choisissez un thème',
    chooseATopic: 'Choisissez un sujet',
    HINT: 'INDICE', 
    Option: 'Option', 
    TypeAnswerHere: 'Tapez votre réponse ici...',
    Write: 'Écrire',
    Speak: 'Parler',
    YouScore: 'Votre Score',
    Detected: 'Détecté',
    Expected: 'Attendu',
    BravoYouFinished: 'Bravo! Vous avez terminé',
    Theme: 'Thème',
    Topic: 'Sujet',
    PronunciationReport: 'Rapport de Prononciation',
    GrammarReport: 'Rapport de Grammaire',
    PracticeAgain: 'Pratiquer à nouveau',
    ReviseThisConversation: 'Réviser cette conversation',
    ChooseAnotherTopic: 'Choisissez un autre sujet',
    DontGiveUp: "Ne lâche pas!",
    YouCanDoIt: "Tu peux le faire",
    KeepPracticing: "Continue à pratiquer",
    KeepGoing: "Continue",
    NotBad: "Pas mal",
    GoodJob: "Bon travail!",
    Bravo: "Bravo!",
    Super: "Super!",
    Excellent: "Excellent!",
    Wow: "Wow!",
    Pro: "Quel pro!",
    OutOfRange: "Valeur hors limites",
    Menu: 'Menu',
    SearchATopic: 'Rechercher un sujet', 
    Home: 'Accueil', 
    MyVocab: 'Mes vocabulaires', 
    Pronunciation: 'Prononciation', 
    Grammar: 'Grammaire', 
    Profile: 'Profil',
    Search: 'Rechercher',
    collapse: 'réduire',
    expand: 'développer',
    Pause: 'Pause',
    Listen: 'Écouter',
    Slow: 'Lent',
    Translation: 'Traduction',
    Stop: 'Arrêter',
    Microphone: 'Microphone',
    Liked: 'Aimé',
    Unliked: 'Non aimé',
    Logo: 'Logo',
    Transcription: 'Transcription',
    Recording: 'Enregistrement',
    Submit: 'Soumettre',
    Delete: 'Supprimer',
    Flag: 'Drapeau',   
    Help: 'Aide',
    PleaseChooseATheme: 'Veuillez choisir un thème',
    PleaseChooseAGrammarGroup: 'Veuillez choisir un groupe de grammaire',
    PleaseChooseAPronunciationGroup: 'Veuillez choisir un groupe de prononciation',
    PracticeSpeaking: 'Pratiquer la parole',
    PracticeWriting: 'Pratiquer l\'écriture',
    TypeHere: 'Tapez ici...',
    Situation: 'Situation',
    change: 'changer',
    AI: 'IA',
    User: 'Utilisateur',
    Audio: 'Audio : ',
    Listentothissentence: 'Écoutez cette phrase<br/><br/>',
    SlowLabel: 'Lent : ',
    Adjustaudiospeed: 'Ajuster la vitesse de l\'audio<br/><br/>',
    TranslationLabel: 'Traduction : ',
    Seetranslation: 'Voir la traduction<br/><br/>',
    MicrophoneLabel: 'Microphone : ',
    Readthissentence: 'Lisez cette phrase et recevez des commentaires instantanés.<br/><br/>Si ce bouton ne fonctionne pas, allez dans les paramètres de votre téléphone -> votre navigateur -> Microphone -> Demander<br/><br/>',
    Expandablesentence: 'Phrases extensibles : ',
    Clickthissentencetolearn: 'Cliquez sur chaque phrase pour apprendre<br/><br/>',
    PracticeLabel: 'Pratique : ',
    Practicewhenyouareready: 'Pratiquez quand vous êtes prêt<br/><br/>',
    changeLabel: 'changer : ',
    changeSituation: 'Choisissez une autre situation<br/><br/>',
    SituationLabel: 'Situation : ',
    situationDescription: 'Afficher ou masquer la description de la situation<br/><br/>',
    TranscriptionLabel: 'Transcription : ',
    Seetranscription: 'Voir la transcription<br/><br/>',
    Options: 'Options : ',
    possibleAnswers: 'Voici des réponses possibles.<br/><br/>',
    Recordyouranswers: 'Enregistrez vos réponses<br/><br/>',
    inputarea: 'Zone de saisie : ',
    Writeyouranswers: 'Tapez vos réponses<br/><br/>',
    Contraction: 'Contraction',
    Masculine: 'Masculin',
    Feminine: 'Féminin',
    Plural: 'Pluriel',
    Pronoun: 'Pronom',
    Verb: 'Verbe',
    PronominalVerb: 'Verbe pronominal',
    Adverb: 'Adverbe',
    MasculineAdjective: 'Adjectif masculin',
    FeminineAdjective: 'Adjectif féminin',
    Adjective: 'Adjectif',
    MasculineNoun: 'Nom masculin',
    FeminineNoun: 'Nom féminin',
    Noun: 'Nom',
    Preposition: 'Préposition',
    Conjunction: 'Conjonction',
    Article: 'Article',
    Determiner: 'Déterminant',
    Interjection: 'Interjection',
    PossessiveAdjective: 'Adjectif possessif' ,
    studySpeak: 'Étudier - Parler',
    studyListen: 'Étudier - Écouter',
    NoviceProgram: 'Programme novice',
GeneralProgramEasy: 'Programme général - facile',
GeneralProgram: 'Programme général',
Contraction: 'Contraction',
GrammarTopic: 'Sujet de grammaire : ',
Note: 'Remarque : ',
Phrase: 'Phrase',
FeedbackSent: 'Commentaire envoyé avec succès !',
FeedbackNotSent: 'Échec de l\'envoi des commentaires. Nous nous excusons. Veuillez envoyer vos commentaires à info@aiducare.com',
SpeakLouder: 'Veuillez parler plus fort',
InstructionVideo: 'Vidéo d\'instruction',
Doesnotsupportvideo: 'Votre navigateur ne prend pas en charge la balise vidéo',
WordLabel: 'Texte : ',
Clickonword: 'Cliquez sur les mots individuels pour étudier',

  },
  es: {
    next: 'Siguiente',
    back: 'Atrás',
    start: 'Empezar',
    summary: 'Resumen',
    study: 'Estudiar',
    Practice: 'Practicar',
    PRACTICE: 'PRACTICAR',
    pronunciation: 'Pronunciación',
    grammar: 'Gramática',
    chooseATheme: 'Elige un tema',
    chooseATopic: 'Elige un tema',
    HINT: 'PISTA', 
    Option: 'Opción', 
    TypeAnswerHere: 'Escribe tu respuesta aquí...',
    Write: 'Escribir',
    Speak: 'Hablar',
    YouScore: 'Tu Puntuación',
    Detected: 'Detectado',
    Expected: 'Esperado',
    BravoYouFinished: '¡Bravo! Has terminado',
    Theme: 'Tema',
    Topic: 'Tópico',
    PronunciationReport: 'Informe de Pronunciación',
    GrammarReport: 'Informe de Gramática',
    PracticeAgain: 'Practicar de nuevo',
    ReviseThisConversation: 'Revisa esta conversación',
    ChooseAnotherTopic: 'Elige otro tema',
    DontGiveUp: "¡No te rindas!",
    YouCanDoIt: "Tú puedes hacerlo",
    KeepPracticing: "Sigue practicando",
    KeepGoing: "Sigue adelante",
    NotBad: "No está mal",
    GoodJob: "¡Buen trabajo!",
    Bravo: "¡Bravo!",
    Super: "¡Súper!",
    Excellent: "¡Excelente!",
    Wow: "¡Guau!",
    Pro: "¡Qué profesional!",
    OutOfRange: "Valor fuera de rango",
    Menu: 'Menú',
    SearchATopic: 'Buscar un tema', 
    Home: 'Inicio', 
    MyVocab: 'Mis vocabularios', 
    Pronunciation: 'Pronunciación', 
    Grammar: 'Gramática', 
    Profile: 'Perfil',
    Search: 'Buscar',
    collapse: 'colapsar',
    expand: 'expandir',
    Pause: 'Pausa',
    Listen: 'Escuchar',
    Slow: 'Lento',
    Translation: 'Traducción',
    Stop: 'Detener',
    Microphone: 'Micrófono',
    Liked: 'Me gusta',
    Unliked: 'No me gusta',
    Logo: 'Logo',
    Transcription: 'Transcripción',
    Recording: 'Grabación',
    Submit: 'Enviar',
    Delete: 'Eliminar',
    Flag: 'Bandera',   
    Help: 'Ayuda',
    PleaseChooseATheme: 'Por favor elige un tema',
    PleaseChooseAGrammarGroup: 'Por favor elige un grupo de gramática',
    PleaseChooseAPronunciationGroup: 'Por favor elige un grupo de pronunciación',
    PracticeSpeaking: 'Practicar hablar',
    PracticeWriting: 'Practicar escribir',
    TypeHere: 'Escribe aquí...',
    Situation: 'Situación',
    change: 'cambiar',
    AI: 'IA',
    User: 'Usuario',
    Audio: 'Audio: ',
    Listentothissentence: 'Escucha esta oración<br/><br/>',
    SlowLabel: 'Lento: ',
    Adjustaudiospeed: 'Ajustar velocidad de audio<br/><br/>',
    TranslationLabel: 'Traducción: ',
    Seetranslation: 'Ver traducción<br/><br/>',
    MicrophoneLabel: 'Micrófono: ',
    Readthissentence: 'Lee esta oración y recibe retroalimentación instantánea.<br/><br/>Si este botón no funciona, ve a Configuración de tu teléfono -> tu navegador -> Micrófono -> Preguntar<br/><br/>',
    Expandablesentence: 'Oraciones expandibles: ',
    Clickthissentencetolearn: 'Haz clic en cada oración para aprender<br/><br/>',
    PracticeLabel: 'Práctica: ',
    Practicewhenyouareready: 'Practica cuando estés listo<br/><br/>',
    changeLabel: 'cambiar: ',
    changeSituation: 'Elige otra situación<br/><br/>',
    SituationLabel: 'Situación: ',
    situationDescription: 'Mostrar u ocultar la descripción de la situación<br/><br/>',
    TranscriptionLabel: 'Transcripción: ',
    Seetranscription: 'Ver transcripción<br/><br/>',
    Options: 'Opciones: ',
    possibleAnswers: 'Estas son posibles respuestas.<br/><br/>',
    Recordyouranswers: 'Graba tus respuestas<br/><br/>',
    inputarea: 'Área de escritura: ',
    Writeyouranswers: 'Escribe tus respuestas<br/><br/>'  ,  
    Contraction: 'Contracción',
    Masculine: 'Masculino',
    Feminine: 'Femenino',
    Plural: 'Plural',
    Pronoun: 'Pronombre',
    Verb: 'Verbo',
    PronominalVerb: 'Verbo pronominal',
    Adverb: 'Adverbio',
    MasculineAdjective: 'Adjetivo masculino',
    FeminineAdjective: 'Adjetivo femenino',
    Adjective: 'Adjetivo',
    MasculineNoun: 'Sustantivo masculino',
    FeminineNoun: 'Sustantivo femenino',
    Noun: 'Sustantivo',
    Preposition: 'Preposición',
    Conjunction: 'Conjunción',
    Article: 'Artículo',
    Determiner: 'Determinante',
    Interjection: 'Interjección',
    PossessiveAdjective: 'Adjetivo posesivo'  ,
    studySpeak: 'Estudiar - Hablar', 
    studyListen: 'Estudiar - Escuchar',
    NoviceProgram: 'Programa principiante',
GeneralProgramEasy: 'Programa general - fácil',
GeneralProgram: 'Programa general',
Contraction: 'Contracción',
GrammarTopic: 'Tema de gramática: ',
Note: 'Nota: ',
Phrase: 'Frase',
FeedbackSent: '¡Comentarios enviados con éxito!',
FeedbackNotSent: 'Error al enviar los comentarios. Lo sentimos. Por favor, envíe sus comentarios a info@aiducare.com',
SpeakLouder: 'Por favor, hable más alto',
InstructionVideo: 'Video instructivo',
    Doesnotsupportvideo: 'Su navegador no soporta la etiqueta de video',
    WordLabel: 'Texto: ',
    Clickonword: 'Haz clic en palabras individuales para estudiar',
    
  },
  pt: {
    next: 'Próximo',
    back: 'Voltar',
    start: 'Começar',
    summary: 'Resumo',
    study: 'Estudar',
    Practice: 'Praticar',
    PRACTICE: 'PRATICAR',
    pronunciation: 'Pronúncia',
    grammar: 'Gramática',
    chooseATheme: 'Escolha um tema',
    chooseATopic: 'Escolha um tópico',
    HINT: 'DICA', 
    Option: 'Opção', 
    TypeAnswerHere: 'Digite sua resposta aqui...',
    Write: 'Escrever',
    Speak: 'Falar',
    YouScore: 'Sua Pontuação',
    Detected: 'Detectado',
    Expected: 'Esperado',
    BravoYouFinished: 'Bravo! Você terminou',
    Theme: 'Tema',
    Topic: 'Tópico',
    PronunciationReport: 'Relatório de Pronúncia',
    GrammarReport: 'Relatório de Gramática',
    PracticeAgain: 'Praticar novamente',
    ReviseThisConversation: 'Revise esta conversa',
    ChooseAnotherTopic: 'Escolha Outro Tópico',
    DontGiveUp: "Não desista!",
    YouCanDoIt: "Você consegue",
    KeepPracticing: "Continue praticando",
    KeepGoing: "Continue",
    NotBad: "Nada mal",
    GoodJob: "Bom trabalho!",
    Bravo: "Bravo!",
    Super: "Super!",
    Excellent: "Excelente!",
    Wow: "Uau!",
    Pro: "Que profissional!",
    OutOfRange: "Valor fora do alcance",
    Menu: 'Menu',
    SearchATopic: 'Buscar um tópico', 
    Home: 'Início', 
    MyVocab: 'Meus vocabulários', 
    Pronunciation: 'Pronúncia', 
    Grammar: 'Gramática', 
    Profile: 'Perfil',
    Search: 'Pesquisar',
    collapse: 'recolher',
    expand: 'expandir',
    Pause: 'Pausa',
    Listen: 'Ouvir',
    Slow: 'Lento',
    Translation: 'Tradução',
    Stop: 'Parar',
    Microphone: 'Microfone',
    Liked: 'Gostado',
    Unliked: 'Não gostado',
    Logo: 'Logo',
    Transcription: 'Transcrição',
    Recording: 'Gravação',
    Submit: 'Enviar',
    Delete: 'Excluir',
    Flag: 'Bandeira',   
    Help: 'Ajuda',
    PleaseChooseATheme: 'Por favor escolha um tema',
    PleaseChooseAGrammarGroup: 'Por favor escolha um grupo de gramática',
    PleaseChooseAPronunciationGroup: 'Por favor escolha um grupo de pronúncia',
    PracticeSpeaking: 'Praticar falar',
    PracticeWriting: 'Praticar escrever',
    TypeHere: 'Digite aqui...',
    Situation: 'Situação',
    change: 'mudar',
    AI: 'IA',
    User: 'Usuário',
    Audio: 'Áudio: ',
    Listentothissentence: 'Ouça esta frase<br/><br/>',
    SlowLabel: 'Lento: ',
    Adjustaudiospeed: 'Ajustar a velocidade do áudio<br/><br/>',
    TranslationLabel: 'Tradução: ',
    Seetranslation: 'Ver tradução<br/><br/>',
    MicrophoneLabel: 'Microfone: ',
    Readthissentence: 'Leia esta frase e receba feedback instantâneo.<br/><br/>Se este botão não funcionar, vá para Configurações do seu telefone -> seu navegador -> Microfone -> Perguntar<br/><br/>',
    Expandablesentence: 'Frases expansíveis: ',
    Clickthissentencetolearn: 'Clique em cada frase para aprender<br/><br/>',
    PracticeLabel: 'Prática: ',
    Practicewhenyouareready: 'Pratique quando estiver pronto<br/><br/>',
    changeLabel: 'mudar: ',
    changeSituation: 'Escolha outra situação<br/><br/>',
    SituationLabel: 'Situação: ',
    situationDescription: 'Mostrar ou ocultar a descrição da situação<br/><br/>',
    TranscriptionLabel: 'Transcrição: ',
    Seetranscription: 'Ver transcrição<br/><br/>',
    Options: 'Opções: ',
    possibleAnswers: 'Estas são respostas possíveis.<br/><br/>',
    Recordyouranswers: 'Grave suas respostas<br/><br/>',
    inputarea: 'Área de digitação: ',
    Writeyouranswers: 'Digite suas respostas<br/><br/>',   
    Contraction: 'Contração',
    Masculine: 'Masculino',
    Feminine: 'Feminino',
    Plural: 'Plural',
    Pronoun: 'Pronome',
    Verb: 'Verbo',
    PronominalVerb: 'Verbo pronominal',
    Adverb: 'Advérbio',
    MasculineAdjective: 'Adjetivo masculino',
    FeminineAdjective: 'Adjetivo feminino',
    Adjective: 'Adjetivo',
    MasculineNoun: 'Substantivo masculino',
    FeminineNoun: 'Substantivo feminino',
    Noun: 'Substantivo',
    Preposition: 'Preposição',
    Conjunction: 'Conjunção',
    Article: 'Artigo',
    Determiner: 'Determinante',
    Interjection: 'Interjeição',
    PossessiveAdjective: 'Adjetivo possessivo' ,
    studySpeak: 'Estudo - Falar', studyListen: 'Estudo - Ouvir',
    NoviceProgram: 'Programa iniciante',
GeneralProgramEasy: 'Programa geral - fácil',
GeneralProgram: 'Programa geral',
Contraction: 'Contração',
GrammarTopic: 'Tópico de gramática: ',
Note: 'Nota: ',
Phrase: 'Frase',
FeedbackSent: 'Feedback enviado com sucesso!',
FeedbackNotSent: 'Falha ao enviar feedback. Pedimos desculpas. Por favor, envie seu feedback para info@aiducare.com',
SpeakLouder: 'Por favor, fale mais alto',
InstructionVideo: 'Vídeo instrucional',
    Doesnotsupportvideo: 'Seu navegador não suporta a tag de vídeo',
    WordLabel: 'Texto: ',
    Clickonword: 'Clique em palavras individuais para estudar',
    
  },
  ar: {
    next: 'التالي',
    back: 'العودة',
    start: 'ابدأ',
    summary: 'الملخص',
    study: 'دراسة',
    Practice: 'ممارسة',
    PRACTICE: 'ممارسة',
    pronunciation: 'النطق',
    grammar: 'القواعد',
    chooseATheme: 'اختر موضوعًا',
    chooseATopic: 'اختر موضوعًا',
    HINT: 'تلميح', 
    Option: 'خيار', 
    TypeAnswerHere: 'اكتب إجابتك هنا',
    Write: 'اكتب',
    Speak: 'تحدث',
    YouScore: 'درجتك',
    Detected: 'تم الكشف',
    Expected: 'متوقع',
    BravoYouFinished: 'برافو! لقد أنهيت',
    Theme: 'موضوع',
    Topic: 'عنوان',
    PronunciationReport: 'تقرير النطق',
    GrammarReport: 'تقرير القواعد',
    PracticeAgain: 'تمرن مرة أخرى',
    ReviseThisConversation: 'راجع هذه المحادثة',
    ChooseAnotherTopic: 'اختر موضوعًا آخر',
    DontGiveUp: "لا تستسلم!",
    YouCanDoIt: "أنت تستطيع",
    KeepPracticing: "واصل التدرب",
    KeepGoing: "استمر",
    NotBad: "ليس سيئًا",
    GoodJob: "عمل جيد!",
    Bravo: "برافو!",
    Super: "ممتاز!",
    Excellent: "ممتاز!",
    Wow: "رائع!",
    Pro: "يا له من محترف!",
    OutOfRange: "القيمة خارج النطاق",
    Menu: 'قائمة',
    SearchATopic: 'البحث عن موضوع', 
    Home: 'الصفحة الرئيسية', 
    MyVocab: 'مفرداتي', 
    Pronunciation: 'النطق', 
    Grammar: 'القواعد', 
    Profile: 'الملف الشخصي',
    Search: 'بحث',
    collapse: 'طي',
    expand: 'توسيع',
    Pause: 'إيقاف مؤقت',
    Listen: 'استمع',
    Slow: 'بطيء',
    Translation: 'ترجمة',
    Stop: 'توقف',
    Microphone: 'ميكروفون',
    Liked: 'أعجبني',
    Unliked: 'لم يعجبني',
    Logo: 'شعار',
    Transcription: 'نسخ',
    Recording: 'تسجيل',
    Submit: 'إرسال',
    Delete: 'حذف',
    Flag: 'علم',    
    Help: 'مساعدة',
    PleaseChooseATheme: 'يرجى اختيار موضوع',
    PleaseChooseAGrammarGroup: 'يرجى اختيار مجموعة قواعد',
    PleaseChooseAPronunciationGroup: 'يرجى اختيار مجموعة نطق',
    PracticeSpeaking: 'التدرب على الكلام',
    PracticeWriting: 'التدرب على الكتابة',
    TypeHere: 'اكتب هنا...',
    Situation: 'الوضع',
    change: 'تغيير',
    AI: 'الذكاء الاصطناعي',
    User: 'المستخدم',
    Audio: 'الصوت: ',
    Listentothissentence: 'استمع إلى هذه الجملة<br/><br/>',
    SlowLabel: 'بطيء: ',
    Adjustaudiospeed: 'تعديل سرعة الصوت<br/><br/>',
    TranslationLabel: 'الترجمة: ',
    Seetranslation: 'عرض الترجمة<br/><br/>',
    MicrophoneLabel: 'الميكروفون: ',
    Readthissentence: 'اقرأ هذه الجملة وتلقى ردود فعل فورية.<br/><br/>إذا لم يعمل هذا الزر، انتقل إلى إعدادات هاتفك -> المتصفح -> ميكروفون -> طلب<br/><br/>',
    Expandablesentence: 'جمل قابلة للتوسيع: ',
    Clickthissentencetolearn: 'انقر على كل جملة للتعلم<br/><br/>',
    PracticeLabel: 'التدريب: ',
    Practicewhenyouareready: 'تدرب عندما تكون مستعدًا<br/><br/>',
    changeLabel: 'تغيير: ',
    changeSituation: 'اختر وضعًا آخر<br/><br/>',
    SituationLabel: 'الوضع: ',
    situationDescription: 'إظهار أو إخفاء وصف الوضع<br/><br/>',
    TranscriptionLabel: 'النص المكتوب: ',
    Seetranscription: 'عرض النص المكتوب<br/><br/>',
    Options: 'خيارات: ',
    possibleAnswers: 'هذه إجابات ممكنة.<br/><br/>',
    Recordyouranswers: 'سجل إجاباتك<br/><br/>',
    inputarea: 'منطقة الكتابة: ',
    Writeyouranswers: 'اكتب إجاباتك<br/><br/>',
    Contraction: 'اختصار',
    Masculine: 'مذكر',
    Feminine: 'مؤنث',
    Plural: 'جمع',
    Pronoun: 'ضمير',
    Verb: 'فعل',
    PronominalVerb: 'فعل انعكاسي',
    Adverb: 'حال',
    MasculineAdjective: 'صفة مذكرة',
    FeminineAdjective: 'صفة مؤنثة',
    Adjective: 'صفة',
    MasculineNoun: 'اسم مذكر',
    FeminineNoun: 'اسم مؤنث',
    Noun: 'اسم',
    Preposition: 'حرف جر',
    Conjunction: 'أداة ربط',
    Article: 'أداة تعريف',
    Determiner: 'محدد',
    Interjection: 'صيحة',
    PossessiveAdjective: 'صفة ملكية',
    studySpeak: 'الدراسة - التحدث', studyListen: 'الدراسة - الاستماع',
    NoviceProgram: 'برنامج المبتدئين',
GeneralProgramEasy: 'برنامج عام - سهل',
GeneralProgram: 'برنامج عام',
Contraction: 'الاختصار',
GrammarTopic: 'موضوع القواعد: ',
Note: 'ملاحظة: ',
Phrase: 'عبارة',
FeedbackSent: 'تم إرسال التعليقات بنجاح!',
FeedbackNotSent: 'فشل في إرسال التعليقات. نحن نعتذر. يرجى إرسال تعليقاتك إلى info@aiducare.com',
SpeakLouder: 'من فضلك تكلم بصوت أعلى',
InstructionVideo: 'فيديو تعليمي',
    Doesnotsupportvideo: 'متصفحك لا يدعم وسم الفيديو',
    WordLabel: 'النص: ',
    Clickonword: 'انقر على الكلمات الفردية للدراسة',
    
  },
  fa: {
    next: 'بعدی',
    back: 'بازگشت',
    start: 'شروع',
    summary: 'خلاصه',
    study: 'مطالعه',
    Practice: 'تمرین',
    PRACTICE: 'تمرین',
    pronunciation: 'تلفظ',
    grammar: 'دستور زبان',
    chooseATheme: 'انتخاب یک موضوع',
    chooseATopic: 'انتخاب یک مبحث',
    HINT: 'راهنمایی', 
    Option: 'گزینه', 
    TypeAnswerHere: 'پاسخ خود را اینجا بنویسید',
    Write: 'نوشتن',
    Speak: 'صحبت کردن',
    YouScore: 'امتیاز شما',
    Detected: 'تشخیص داده شد',
    Expected: 'انتظار می رفت',
    BravoYouFinished: 'آفرین! شما تمام کردید',
    Theme: 'موضوع',
    Topic: 'عنوان',
    PronunciationReport: 'گزارش تلفظ',
    GrammarReport: 'گزارش دستور زبان',
    PracticeAgain: 'دوباره تمرین کن',
    ReviseThisConversation: 'این مکالمه را مرور کنید',
    ChooseAnotherTopic: 'موضوع دیگری را انتخاب کنید',
    DontGiveUp: "تسلیم نشو!",
    YouCanDoIt: "تو می‌توانی",
    KeepPracticing: "ادامه بده",
    KeepGoing: "ادامه بده",
    NotBad: "بد نیست",
    GoodJob: "کارت عالی بود!",
    Bravo: "آفرین!",
    Super: "عالی!",
    Excellent: "عالی!",
    Wow: "وای!",
    Pro: "چه حرفه‌ای!",
    OutOfRange: "مقدار خارج از محدوده است",
    Menu: 'منو',
    SearchATopic: 'جستجوی یک موضوع', 
    Home: 'خانه', 
    MyVocab: 'واژگان من', 
    Pronunciation: 'تلفظ', 
    Grammar: 'دستور زبان', 
    Profile: 'پروفایل',
    Search: 'جستجو',
    collapse: 'جمع کردن',
    expand: 'گسترش دادن',
    Pause: 'توقف',
    Listen: 'گوش دادن',
    Slow: 'آهسته',
    Translation: 'ترجمه',
    Stop: 'توقف',
    Microphone: 'میکروفون',
    Liked: 'پسندیده شد',
    Unliked: 'پسندیده نشد',
    Logo: 'لوگو',
    Transcription: 'رونوشت',
    Recording: 'ضبط',
    Submit: 'ارسال',
    Delete: 'حذف',
    Flag: 'پرچم',   
    Help: 'کمک',
    PleaseChooseATheme: 'لطفاً یک موضوع انتخاب کنید',
    PleaseChooseAGrammarGroup: 'لطفاً یک گروه گرامر انتخاب کنید',
    PleaseChooseAPronunciationGroup: 'لطفاً یک گروه تلفظ انتخاب کنید',
    PracticeSpeaking: 'تمرین صحبت کردن',
    PracticeWriting: 'تمرین نوشتن',
    TypeHere: 'اینجا تایپ کنید...',
    Situation: 'وضعیت',
    change: 'تغییر',
    AI: 'هوش مصنوعی',
    User: 'کاربر',
    Audio: 'صدا: ',
    Listentothissentence: 'به این جمله گوش دهید<br/><br/>',
    SlowLabel: 'آهسته: ',
    Adjustaudiospeed: 'تنظیم سرعت صدا<br/><br/>',
    TranslationLabel: 'ترجمه: ',
    Seetranslation: 'مشاهده ترجمه<br/><br/>',
    MicrophoneLabel: 'میکروفون: ',
    Readthissentence: 'این جمله را بخوانید و بازخورد فوری دریافت کنید.<br/><br/>اگر این دکمه کار نمی‌کند، به تنظیمات تلفن خود بروید -> مرورگر خود -> میکروفون -> درخواست<br/><br/>',
    Expandablesentence: 'جملات گسترش‌پذیر: ',
    Clickthissentencetolearn: 'برای یادگیری روی هر جمله کلیک کنید<br/><br/>',
    PracticeLabel: 'تمرین: ',
    Practicewhenyouareready: 'وقتی آماده شدید تمرین کنید<br/><br/>',
    changeLabel: 'تغییر: ',
    changeSituation: 'وضعیت دیگری را انتخاب کنید<br/><br/>',
    SituationLabel: 'وضعیت: ',
    situationDescription: 'نمایش یا پنهان کردن توضیحات وضعیت<br/><br/>',
    TranscriptionLabel: 'رونویسی: ',
    Seetranscription: 'مشاهده رونویسی<br/><br/>',
    Options: 'گزینه‌ها: ',
    possibleAnswers: 'این‌ها پاسخ‌های احتمالی هستند.<br/><br/>',
    Recordyouranswers: 'پاسخ‌های خود را ضبط کنید<br/><br/>',
    inputarea: 'منطقه نوشتاری: ',
    Writeyouranswers: 'پاسخ‌های خود را تایپ کنید<br/><br/>'   ,
    Contraction: 'انقباض',
    Masculine: 'مذکر',
    Feminine: 'مونث',
    Plural: 'جمع',
    Pronoun: 'ضمیر',
    Verb: 'فعل',
    PronominalVerb: 'فعل انعکاسی',
    Adverb: 'قید',
    MasculineAdjective: 'صفت مذکر',
    FeminineAdjective: 'صفت مونث',
    Adjective: 'صفت',
    MasculineNoun: 'اسم مذکر',
    FeminineNoun: 'اسم مونث',
    Noun: 'اسم',
    Preposition: 'حرف اضافه',
    Conjunction: 'حرف ربط',
    Article: 'حرف تعریف',
    Determiner: 'معرفه',
    Interjection: 'حرف ندا',
    PossessiveAdjective: 'صفت ملکی'   ,
    studySpeak: 'مطالعه - صحبت کردن', studyListen: 'مطالعه - گوش دادن',
    NoviceProgram: 'برنامه مبتدی',
GeneralProgramEasy: 'برنامه عمومی - آسان',
GeneralProgram: 'برنامه عمومی',
Contraction: 'انقباض',
GrammarTopic: 'موضوع دستور زبان: ',
Note: 'توجه: ',
Phrase: 'عبارت',
FeedbackSent: 'بازخورد با موفقیت ارسال شد!',
FeedbackNotSent: 'ارسال بازخورد با شکست مواجه شد. پوزش می‌خواهیم. لطفاً بازخورد خود را به info@aiducare.com ارسال کنید',
SpeakLouder: 'لطفاً بلندتر صحبت کنید',
InstructionVideo: 'ویدئوی آموزشی',
    Doesnotsupportvideo: 'مرورگر شما از تگ ویدئو پشتیبانی نمی‌کند',
    WordLabel: 'متن: ',
    Clickonword: 'روی کلمات جداگانه کلیک کنید تا مطالعه کنید',
    
  },
  zh: {
    next: '下一步',
    back: '返回',
    start: '开始',
    summary: '总结',
    study: '学习',
    Practice: '练习',
    PRACTICE: '练习',
    pronunciation: '发音',
    grammar: '语法',
    chooseATheme: '选择一个主题',
    chooseATopic: '选择一个话题',
    HINT: '提示', 
    Option: '选项', 
    TypeAnswerHere: '在这里输入你的答案...',
    Write: '写',
    Speak: '说',
    YouScore: '你的得分',
    Detected: '检测到',
    Expected: '预期',
    BravoYouFinished: '太棒了！你刚刚完成了',
    Theme: '主题',
    Topic: '话题',
    PronunciationReport: '发音报告',
    GrammarReport: '语法报告',
    PracticeAgain: '再练习一次',
    ReviseThisConversation: '复习这段对话',
    ChooseAnotherTopic: '选择另一个话题',
    DontGiveUp: "不要放弃!",
    YouCanDoIt: "你可以的",
    KeepPracticing: "继续练习",
    KeepGoing: "继续",
    NotBad: "不错",
    GoodJob: "干得好!",
    Bravo: "太棒了!",
    Super: "超级棒!",
    Excellent: "优秀!",
    Wow: "哇!",
    Pro: "真是高手!",
    OutOfRange: "数值超出范围",
    Menu: '指数',
    SearchATopic: '搜索主题', 
    Home: '首页', 
    MyVocab: '我的词汇表', 
    Pronunciation: '发音', 
    Grammar: '语法', 
    Profile: '个人资料',
    Search: '搜索',
    collapse: '折叠',
    expand: '展开',
    Pause: '暂停',
    Listen: '听',
    Slow: '慢',
    Translation: '翻译',
    Stop: '停止',
    Microphone: '麦克风',
    Liked: '喜欢',
    Unliked: '不喜欢',
    Logo: '标志',
    Transcription: '转录',
    Recording: '录音',
    Submit: '提交',
    Delete: '删除',
    Flag: '旗帜',    
    Help: '帮助',
    PleaseChooseATheme: '请选择一个主题',
    PleaseChooseAGrammarGroup: '请选择一个语法组',
    PleaseChooseAPronunciationGroup: '请选择一个发音组',
    PracticeSpeaking: '练习说话',
    PracticeWriting: '练习写作',
    TypeHere: '在这里输入...',
    Situation: '情景',
    change: '更改',
    AI: '人工智能',
    User: '用户',
    Audio: '音频：',
    Listentothissentence: '听这句话<br/><br/>',
    SlowLabel: '慢速：',
    Adjustaudiospeed: '调整音频速度<br/><br/>',
    TranslationLabel: '翻译：',
    Seetranslation: '查看翻译<br/><br/>',
    MicrophoneLabel: '麦克风：',
    Readthissentence: '读这句话并获得即时反馈。<br/><br/>如果此按钮不起作用，请转到手机设置 -> 浏览器 -> 麦克风 -> 请求<br/><br/>',
    Expandablesentence: '可扩展句子：',
    Clickthissentencetolearn: '点击每个句子进行学习<br/><br/>',
    PracticeLabel: '练习：',
    Practicewhenyouareready: '准备好时进行练习<br/><br/>',
    changeLabel: '更改：',
    changeSituation: '选择另一种情况<br/><br/>',
    SituationLabel: '情景：',
    situationDescription: '显示或隐藏情景描述<br/><br/>',
    TranscriptionLabel: '转录：',
    Seetranscription: '查看转录<br/><br/>',
    Options: '选项：',
    possibleAnswers: '这些是可能的答案。<br/><br/>',
    Recordyouranswers: '记录您的答案<br/><br/>',
    inputarea: '输入区：',
    Writeyouranswers: '输入您的答案<br/><br/>',
    Contraction: '缩写',
    Masculine: '阳性',
    Feminine: '阴性',
    Plural: '复数',
    Pronoun: '代词',
    Verb: '动词',
    PronominalVerb: '代词动词',
    Adverb: '副词',
    MasculineAdjective: '阳性形容词',
    FeminineAdjective: '阴性形容词',
    Adjective: '形容词',
    MasculineNoun: '阳性名词',
    FeminineNoun: '阴性名词',
    Noun: '名词',
    Preposition: '介词',
    Conjunction: '连词',
    Article: '冠词',
    Determiner: '限定词',
    Interjection: '感叹词',
    PossessiveAdjective: '所有格形容词',
    studySpeak: '学习 - 口语', studyListen: '学习 - 听力',
    NoviceProgram: '初学者计划',
GeneralProgramEasy: '通用计划 - 简单',
GeneralProgram: '通用计划',
Contraction: '缩写',
GrammarTopic: '语法主题：',
Note: '注意：',
Phrase: '短语',
FeedbackSent: '反馈已成功提交！',
FeedbackNotSent: '提交反馈失败。我们深表歉意。请将您的反馈发送至 info@aiducare.com',
SpeakLouder: '请大声点说',
InstructionVideo: '教学视频',
    Doesnotsupportvideo: '您的浏览器不支持视频标签',
    WordLabel: '文本：',
    Clickonword: '点击单个单词进行学习',
    
  },
  vi: {
    next: 'Tiếp theo',
    back: 'Quay lại',
    start: 'Bắt đầu',
    summary: 'Tóm tắt',
    study: 'Học tập',
    Practice: 'Luyện tập',
    PRACTICE: 'LUYỆN TẬP',
    pronunciation: 'Phát âm',
    grammar: 'Ngữ pháp',
    chooseATheme: 'Chọn đề tài',
    chooseATopic: 'Chọn chủ đề',
    HINT: 'GỢI Ý', 
    Option: 'Lựa chọn', 
    TypeAnswerHere: 'Nhập câu trả lời của bạn tại đây...',
    Write: 'Viết',
    Speak: 'Nói',
    YouScore: 'Điểm của bạn',
    Detected: 'Đã phát hiện',
    Expected: 'Mong đợi',
    BravoYouFinished: 'Hoan hô! Bạn đã hoàn thành',
    Theme: 'Đề tài',
    Topic: 'Chủ đề',
    PronunciationReport: 'Báo cáo Phát âm',
    GrammarReport: 'Báo cáo Ngữ pháp',
    PracticeAgain: 'Luyện tập lại',
    ReviseThisConversation: 'Xem lại cuộc trò chuyện này',
    ChooseAnotherTopic: 'Chọn chủ đề khác',
    DontGiveUp: "Đừng bỏ cuộc!",
    YouCanDoIt: "Bạn có thể làm được",
    KeepPracticing: "Tiếp tục luyện tập",
    KeepGoing: "Tiếp tục",
    NotBad: "Không tệ",
    GoodJob: "Làm tốt lắm!",
    Bravo: "Tuyệt vời!",
    Super: "Siêu!",
    Excellent: "Xuất sắc!",
    Wow: "Wow!",
    Pro: "Bạn thật chuyên nghiệp!",
    OutOfRange: "Giá trị vượt quá phạm vi",
    Menu: 'Mục lục',
    SearchATopic: 'Tìm kiếm một chủ đề', 
    Home: 'Trang chủ', 
    MyVocab: 'Từ vựng của tôi', 
    Pronunciation: 'Phát âm', 
    Grammar: 'Ngữ pháp', 
    Profile: 'Hồ sơ',
    Search: 'Tìm kiếm',
    collapse: 'thu gọn',
    expand: 'mở rộng',
    Pause: 'Tạm dừng',
    Listen: 'Nghe',
    Slow: 'Chậm',
    Translation: 'Dịch',
    Stop: 'Dừng',
    Microphone: 'Micro',
    Liked: 'Đã thích',
    Unliked: 'Không thích',
    Logo: 'Logo',
    Transcription: 'Phiên âm',
    Recording: 'Ghi âm',
    Submit: 'Gửi',
    Delete: 'Xóa',
    Flag: 'Cờ',   
    Help: 'Trợ giúp',
    PleaseChooseATheme: 'Vui lòng chọn một chủ đề',
    PleaseChooseAGrammarGroup: 'Vui lòng chọn một nhóm ngữ pháp',
    PleaseChooseAPronunciationGroup: 'Vui lòng chọn một nhóm phát âm',
    PracticeSpeaking: 'Luyện nói',
    PracticeWriting: 'Luyện viết',
    TypeHere: 'Nhập ở đây...',
    Situation: 'Tình huống',
    change: 'thay đổi',
    AI: 'AI',
    User: 'Người dùng',
    Audio: 'Âm thanh: ',
    Listentothissentence: 'Nghe câu này<br/><br/>',
    SlowLabel: 'Chậm: ',
    Adjustaudiospeed: 'Điều chỉnh tốc độ âm thanh<br/><br/>',
    TranslationLabel: 'Dịch: ',
    Seetranslation: 'Xem bản dịch<br/><br/>',
    MicrophoneLabel: 'Micrô: ',
    Readthissentence: 'Đọc câu này và nhận phản hồi ngay lập tức.<br/><br/>Nếu nút này không hoạt động, hãy vào Cài đặt điện thoại của bạn -> trình duyệt của bạn -> Micrô -> Yêu cầu<br/><br/>',
    Expandablesentence: 'Câu có thể mở rộng: ',
    Clickthissentencetolearn: 'Nhấp vào từng câu để học<br/><br/>',
    PracticeLabel: 'Luyện tập: ',
    Practicewhenyouareready: 'Luyện tập khi bạn sẵn sàng<br/><br/>',
    changeLabel: 'thay đổi: ',
    changeSituation: 'Chọn một tình huống khác<br/><br/>',
    SituationLabel: 'Tình huống: ',
    situationDescription: 'Hiển thị hoặc ẩn mô tả tình huống<br/><br/>',
    TranscriptionLabel: 'Chép lại: ',
    Seetranscription: 'Xem bản chép lại<br/><br/>',
    Options: 'Tùy chọn: ',
    possibleAnswers: 'Đây là các câu trả lời có thể.<br/><br/>',
    Recordyouranswers: 'Ghi lại câu trả lời của bạn<br/><br/>',
    inputarea: 'Khu vực nhập: ',
    Writeyouranswers: 'Nhập câu trả lời của bạn<br/><br/>',
    Contraction: 'Rút gọn',
    Masculine: 'Giống đực',
    Feminine: 'Giống cái',
    Plural: 'Số nhiều',
    Pronoun: 'Đại từ',
    Verb: 'Động từ',
    PronominalVerb: 'Động từ đại từ',
    Adverb: 'Trạng từ',
    MasculineAdjective: 'Tính từ giống đực',
    FeminineAdjective: 'Tính từ giống cái',
    Adjective: 'Tính từ',
    MasculineNoun: 'Danh từ giống đực',
    FeminineNoun: 'Danh từ giống cái',
    Noun: 'Danh từ',
    Preposition: 'Giới từ',
    Conjunction: 'Liên từ',
    Article: 'Mạo từ',
    Determiner: 'Từ hạn định',
    Interjection: 'Thán từ',
    PossessiveAdjective: 'Tính từ sở hữu'  ,
    studySpeak: 'Học - Nói', studyListen: 'Học - Nghe',
    NoviceProgram: 'Chương trình người mới',
GeneralProgramEasy: 'Chương trình chung - dễ',
GeneralProgram: 'Chương trình chung',
Contraction: 'Sự rút gọn',
GrammarTopic: 'Chủ đề ngữ pháp: ',
Note: 'Lưu ý: ',
Phrase: 'Cụm từ',
FeedbackSent: 'Phản hồi đã được gửi thành công!',
FeedbackNotSent: 'Gửi phản hồi không thành công. Chúng tôi xin lỗi. Vui lòng gửi phản hồi của bạn đến info@aiducare.com',
SpeakLouder: 'Làm ơn nói lớn hơn',
InstructionVideo: 'Video hướng dẫn',
    Doesnotsupportvideo: 'Trình duyệt của bạn không hỗ trợ video',
    WordLabel: 'Văn bản: ',
    Clickonword: 'Nhấp vào từng từ để học',
    
  }
};

// Utility function to get the correct theme name based on the selected language
export const getThemeName = (theme, selectedLanguage) => {
  const languageMapping = {
    en: 'theme_name_en',
    es: 'theme_name_es',
    pt: 'theme_name_pt',
    ar: 'theme_name_ar',
    fa: 'theme_name_fa',
    zh: 'theme_name_zh',
    vi: 'theme_name_vi'
  };
  const themeKey = languageMapping[selectedLanguage] || 'theme_name_en';  
  // Return the theme name for the selected language or fallback to English
  return theme[themeKey] || theme.theme_name_en;
};

export const getTopicName = (topic, selectedLanguage) => {
  const languageMapping = {
    en: 'topic_name_en',
    es: 'topic_name_es',
    pt: 'topic_name_pt',
    ar: 'topic_name_ar',
    fa: 'topic_name_fa',
    zh: 'topic_name_zh',
    vi: 'topic_name_vi'
  };
  const topicKey = languageMapping[selectedLanguage] || 'topic_name_en';
  return topic[topicKey] || topic.topic_name_en;
};

export const getGroupName = (group, selectedLanguage) => {
  const languageMapping = {
    en: 'group_name_en',
    es: 'group_name_es',
    pt: 'group_name_pt',
    ar: 'group_name_ar',
    fa: 'group_name_fa',
    zh: 'group_name_zh',
    vi: 'group_name_vi'
  };
  const groupKey = languageMapping[selectedLanguage] || 'group_name_en';
  return group[groupKey] || group.group_name_en;
};

export const getInstruction = (content, selectedLanguage) => {
  const languageMapping = {
    en: 'instruction_en',
    es: 'instruction_es',
    pt: 'instruction_pt',
    ar: 'instruction_ar',
    fa: 'instruction_fa',
    zh: 'instruction_zh',
    vi: 'instruction_vi'
  };
  const instructionKey = languageMapping[selectedLanguage] || 'instruction_en';
  return content[instructionKey] || content.instruction_en;
};

export const getNote = (content, selectedLanguage) => {
  const languageMapping = {
    en: 'note_en',
    es: 'note_es',
    pt: 'note_pt',
    ar: 'note_ar',
    fa: 'note_fa',
    zh: 'note_zh',
    vi: 'note_vi'
  };
  const noteKey = languageMapping[selectedLanguage] || 'note_en';
  return content[noteKey] || content.note_en;
};

export const getTranslatedSentence = (content, selectedLanguage) => {
  return content[selectedLanguage] || content.en;
};

export const getInstructionVideo = (selectedLanguage) => {
  const videoMapping = {
    en: '/Video/instruction video EN.mp4',
    es: '/Video/instruction video ES.mp4',
    pt: '/Video/instruction video PT.mp4',
    ar: '/Video/instruction video AR.mp4',
    fa: '/Video/instruction video FA.mp4',
    zh: '/Video/instruction video ZH.mp4',
    vi: '/Video/instruction video VI.mp4'
  };
  const video = videoMapping[selectedLanguage] || '/Video/instruction video EN.mp4';  
  return video;
};