import React, { useState, useEffect, useRef, useContext} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { useFavorites } from '../StudyMenu/FavoriteVocab';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { useClickWord } from '../Reusables/ClickWord';
import { getNote, playWordAudio, showPartOfSpeech, highlightMatches, isObjectEmpty, getClassName, determineTopicNameFromVerbName, determineIpaPopup, frToAudio, translations, getTranslatedSentence, highlightMatchesWord } from '../Reusables/Utilities';
import { useRecordUser } from '../Reusables/RecordUser';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers } from '../Practice/UserAnswers';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './Learn.css';

const SpeakingSentences = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  const {  index, fromSummary } = location.state || { index: 0 , fromSummary: false};  
  const [cameFromSummary, setCameFromSummary] = useState(fromSummary);  // handling go to next sentence in speaking
  const [current, setCurrent] = useState(index);
  const { favorites, toggleFavorite } = useFavorites();
  const {isPlaying, isPlayingSlower, playPause, playPauseIPA, stopAudio, play, slowerPause, currentAudioFile} = usePlayAudio(); 
  const {selectedPhrase , setSelectedPhrase, selectedVocab, setSelectedVocab, handleWordClick} = useClickWord();
  const {setTranscription, transcription, recording, startRecording, stopRecording} = useRecordUser();  
  // const [detectedSentence, setDetectedSentence] = useState('');
  const [formattedSentence, setFormattedSentence] = useState('');
  // const previousTranscriptionRef = useRef(transcription);
  const { sentencesForStudy, sentencesForStudyForSpeak, fetchConversations} = useContext(DataContext);
  const { program, topic, englishTheme, englishTopic} = useUserAnswers();
  const { selectedLanguage } = useLanguage();
  const [speakWord, setSpeakWord] = useState(false);
  const [speakSentence, setSpeakSentence] = useState(false);

  useEffect(() => {
    setCurrent(index); 

    if (sentencesForStudy.length === 0) {
      fetchConversations(selectedLanguage, program, topic);
      setCurrent(0);  
    } 
     
    // setDetectedSentence('');
    setFormattedSentence('');
    setSelectedPhrase('');
    setSelectedVocab({});
    stopAudio();
    stopRecording();

    return () => {
      stopAudio(); // when the component unmounts
    };
  }, [topic]); // Empty dependency array means this effect runs only once, and the cleanup runs when the component unmounts
  
  const goBack = () => {    
    // setDetectedSentence('');
    setFormattedSentence('');
    setSelectedPhrase('');
    setSelectedVocab({});
    stopRecording();
    
    if (fromSummary && cameFromSummary && current < sentencesForStudy.length - 1) { 
      navigate('/summary-page'); 
    } else if (current === 0 && program !== 'novice') {      
      navigate('/topic-selection');      
    } else if (current === 0 && program === 'novice') {      
      navigate('/novice-program');      
    } else {      
      setCameFromSummary(false);
      setCurrent(current - 1);          // remains in speaking-selection page    
      stopAudio();        
    }
  };

  const goToNext = () => {
    // setDetectedSentence('');
    setFormattedSentence('');
    setSelectedPhrase('');
    setSelectedVocab({});
    stopRecording();
    if (current < sentencesForStudy.length - 1) {      
      setCameFromSummary(false);
      setCurrent(current + 1);          // remains in speaking-selection page
      stopAudio();
    } else {    
      navigate('/summary-page', { state: { fromLastSpeaking : true } });
    }
  };

  /********** TRANSLATION **********/ 
  const [showTranslation, setShowTranslation] = useState(false);
  const translate = () => {    
    setShowTranslation(!showTranslation);
 }  
   
  /********** HIGHLIGHT SENTENCE **********/
  useEffect(() => {     
    // if (transcription !== previousTranscriptionRef.current && formattedSentence === '') {
      // previousTranscriptionRef.current = transcription;
    if (transcription !== ''){ 
      console.log('detected sentence = ' + transcription); 
      if (speakWord) {
        // Dynamically set color for the .selected class
        const styleSheet = document.styleSheets[0];
        const ruleIndex = Array.from(styleSheet.cssRules).findIndex(rule => rule.selectorText === '.selected');
        if (highlightMatchesWord(transcription.replace(/'/g, "\'"), selectedPhrase) ){
          if (ruleIndex !== -1) {
            // Modify existing rule if .selected already exists
            styleSheet.cssRules[ruleIndex].style.color = '#00C667'; 
          } else {
            // Otherwise, insert a new rule
            styleSheet.insertRule('.selected { color: #00C667; }', styleSheet.cssRules.length);
          }
        } else {
          if (ruleIndex !== -1) {
            styleSheet.cssRules[ruleIndex].style.color = '#F40000'; 
          } else {
            styleSheet.insertRule('.selected { color: #F40000; }', styleSheet.cssRules.length);
          }
        }
        // }
        setSpeakWord(false);
      } else if (speakSentence) {
        setFormattedSentence(highlightMatches(transcription.replace(/'/g, "\'"), originalSentence)); // replace ' by \'
        setSpeakSentence(false);
      }   
      setTranscription('');
    }     
  }, [transcription]); 


  /********** POPUP INSTRUCTION **********/  
  const audioRef = useRef(null);
  const slowRef = useRef(null);
  const translationRef = useRef(null);
  const microphoneRef = useRef(null);
  const wordRef = useRef(null);

  let steps = [];
  if (sentencesForStudyForSpeak[current]) {
    steps = [
      { ref: audioRef, label: translations[selectedLanguage].Audio , text: translations[selectedLanguage].Listentothissentence },
      { ref: slowRef, label: translations[selectedLanguage].SlowLabel, text: translations[selectedLanguage].Adjustaudiospeed },
      { ref: translationRef, label: translations[selectedLanguage].TranslationLabel, text: translations[selectedLanguage].Seetranslation },    
      { ref: microphoneRef, label: translations[selectedLanguage].MicrophoneLabel, text: translations[selectedLanguage].Readthissentence },  
      { ref: wordRef, label: translations[selectedLanguage].WordLabel, text: translations[selectedLanguage].Clickonword },          
    ];
  } else {
    steps = [
      { ref: audioRef, label: translations[selectedLanguage].Audio , text: translations[selectedLanguage].Listentothissentence },
      { ref: slowRef, label: translations[selectedLanguage].SlowLabel, text: translations[selectedLanguage].Adjustaudiospeed },
      { ref: translationRef, label: translations[selectedLanguage].TranslationLabel, text: translations[selectedLanguage].Seetranslation },  
      { ref: wordRef, label: translations[selectedLanguage].WordLabel, text: translations[selectedLanguage].Clickonword },  
    ];
  }
  
  const originalSentence = sentencesForStudy?.[current]?.fr;

  /********** link to grammar or ipa lessons **********/ 
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isIPAClick, setIsIPAClick] = useState(false);
  const [ipaContent, setipaContent]  = useState('');
  const { grammarContent, setGrammarContent, fetchGrammarContent } = useContext(DataContext);
  
  // Function to fetch content and handle opening the popup
  const handleClickVerbName = () => {
    const topicName = determineTopicNameFromVerbName(selectedVocab.tense_type_fr, selectedVocab.tense_name_fr);          
    handleClick(topicName);
  }

  const specialAdjectives = ['ancient', 'brave', 'certain', 'cher', 'dernier', 'grand', 'pauvre', 'propre', 'sale', 'sacré', 'simple', 'vrai'];
  
  const handleClick = async (topicName) => {
    try {    
      setCurrentTopicName(topicName);  
      await fetchGrammarContent(topicName, selectedLanguage);  // Fetch the content from the server
      setIsPopupOpen(true); // Open the popup
    } catch (error) {
      console.error('Error fetching topic content:', error);
    }
  };

  const handleIPAClick = async () => {
    try {    
      let content = determineIpaPopup(selectedVocab.ipa);
      setipaContent(content);
      setIsIPAClick(true);
      setIsPopupOpen(true); // Open the popup
    } catch (error) {
      console.error('Error fetching topic content:', error);
    }
  };

  // Function to close the popup
  const handleClose = () => {
    setIsPopupOpen(false);
    setGrammarContent('');
    setipaContent('');
    setIsIPAClick(false);
  };

  
  const startRecordingSentence = () => {
    setSpeakSentence(true);
    startRecording(selectedLanguage);
  }
  const startRecordingWord = () => {
    setSpeakWord(true);
    startRecording(selectedLanguage);
  }

  const [topicHistory, setTopicHistory] = useState([]);
  const [currentTopicName, setCurrentTopicName] = useState('');

  // Function to go back to the previous topic
  const backButtonPopupClick = () => {
    if (topicHistory.length > 0) {
      const previousTopic = topicHistory[topicHistory.length - 1];
      setTopicHistory(topicHistory.slice(0, -1)); // Remove the last topic from the stack
      setCurrentTopicName(previousTopic);
      fetchGrammarContent(previousTopic, selectedLanguage); // Fetch the previous topic's content
    } else {
      setIsPopupOpen(false);
      setGrammarContent('');
      setipaContent('');
      setIsIPAClick(false);
    }
  };

  // useEffect to handle adding event listeners after content is loaded
  useEffect(() => {
    if (isPopupOpen) {
      const links = document.querySelectorAll('.link');
      const handleClickWrapper = (event) => {
        event.preventDefault(); // Prevent default link behavior
        const newTopicName = event.target.getAttribute('data-topic');
        setTopicHistory(prevHistory => [...prevHistory, currentTopicName]);
        setCurrentTopicName(newTopicName);
        fetchGrammarContent(newTopicName, selectedLanguage); // Fetch the new content
      };

      links.forEach(link => {
        link.addEventListener('click', handleClickWrapper);
      });

      // Clean up event listeners on component unmount
      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleClickWrapper);
        });
      };
    }
  }, [isPopupOpen, grammarContent]);

  useEffect(() => {
    const buttons = document.querySelectorAll('.listen-button');
    
    const handlePlayPause = (event) => {
      const audio = event.currentTarget.getAttribute('data-audio');
      playPauseIPA(audio);      
    };

    buttons.forEach(button => {
      button.addEventListener('click', handlePlayPause);
    });
  
    // Cleanup: Remove event listeners when component unmounts or re-renders
    return () => {
      buttons.forEach(button => {
        button.removeEventListener('click', handlePlayPause);
      });
    };
  }, [ipaContent]);

  /********** to close vocabSelect **********/  
  const vocabRef = useRef(null);
  const handleClickOutside = (event) => {
    setFormattedSentence('');

    if (event.target.closest('.next-button')) {
      return; // Skip resetting selectedVocab if the "Next" button is clicked
    }

    if (vocabRef.current && !vocabRef.current.contains(event.target)) {
      setSelectedVocab({});
      setSelectedPhrase('');
    }

    //reset words color
    const styleSheet = document.styleSheets[0];
    const ruleIndex = Array.from(styleSheet.cssRules).findIndex(rule => rule.selectorText === '.selected');      
    if (ruleIndex !== -1) {
      // Modify existing rule if .selected already exists
      styleSheet.cssRules[ruleIndex].style.color = '#062E5F'; // Replace with desired color
    } else {
      // Otherwise, insert a new rule
      styleSheet.insertRule('.selected { color: #062E5F; }', styleSheet.cssRules.length);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // let colorStyle = { color: (speakWord && selectedPhrase === transcription) ? 'green' : (speakWord ? 'red' : 'inherit') };

  /********** INTERFACE **********/
  return (
    <div className="study-page">
      {topic !== '' ? (
      <div>
      <div className="top-row">
        <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{sentencesForStudyForSpeak[current] ? (translations[selectedLanguage].studySpeak) : (translations[selectedLanguage].studyListen)}</h3>
        <BurgerMenuComponent />  
      </div>   
      <div className="study-body">           
          {formattedSentence  && formattedSentence !== '' 
          ? 
           <div ref={wordRef} className="sentence-with-controls-2"><span><b>{formattedSentence}</b></span></div>           
          :  
          (   
            <div ref={wordRef} className="sentence-with-controls-1">          
            {originalSentence && originalSentence.split(' ').map((word, index) => (
            <span>
            <span 
            key={index}
            className={getClassName(
              JSON.parse(sentencesForStudy[current].vocab), 
              selectedPhrase, 
              word
            )}
            onClick={() => handleWordClick(JSON.parse(sentencesForStudy[current]?.vocab), word, originalSentence, index)}   
          >
              <b>{word}</b>
            </span>
            &nbsp;</span>
           ))  }   </div>       
            )} 
          <div> 
            <button ref={audioRef} className="speaker-button" onClick={() => playPause(frToAudio(sentencesForStudy[current].fr, englishTheme, englishTopic))}>
              <img src={isPlaying && currentAudioFile === frToAudio(sentencesForStudy[current].fr, englishTheme, englishTopic) ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying && currentAudioFile === frToAudio(sentencesForStudy[current].fr, englishTheme, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
            </button>
            <button ref={slowRef} className="slowdown-button" onClick={() => slowerPause(frToAudio(sentencesForStudy[current].fr, englishTheme, englishTopic))}>
              <img src={isPlayingSlower && currentAudioFile === frToAudio(sentencesForStudy[current]?.fr, englishTheme, englishTopic) ? "/Icons/pause.svg" : "/Icons/turtle.svg"} alt={isPlayingSlower && currentAudioFile === frToAudio(sentencesForStudy[current].fr, englishTheme, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} /> 
            </button>
            <button ref={translationRef} className={`translation-button ${showTranslation ? 'highlighted' : ''}`} onClick={() => translate()}>
              <img
                src={showTranslation ? "/Icons/translation-highlighted.svg" : "/Icons/translation.svg"}
                alt={translations[selectedLanguage].Translation}
              />
            </button>
          </div>      
          
        {isPlayingSlower && currentAudioFile === frToAudio(sentencesForStudy[current].fr, englishTheme, englishTopic) &&
          <div className="translation-area" style={{maxWidth:'500px'}}> <PlaybackRateControl /></div>  }
        {showTranslation && (
          <div className="translation-area">
            <b>{getTranslatedSentence(sentencesForStudy[current], selectedLanguage)}</b>
          </div>
        )}        

        {(selectedVocab && !isObjectEmpty(selectedVocab)) ? (                         
            <div key={index} className="vocab-item" ref={vocabRef}>
              <div onClick={recording ? stopRecording : startRecordingWord}> 
                {recording ? (
                  <button  className="recording-animation-small">
                    <img src="/Icons/stop-practice.svg" alt={translations[selectedLanguage].stop} />
                    {/* <b>{translations[selectedLanguage].Stop}</b> */}
                  </button>
                )
                :
                  <div><button className="microphone-button-small">
                  <img src="/Icons/microphone alone.svg" alt={translations[selectedLanguage].Microphone} />
                  </button></div>
                }
              </div>
              {/* other forms or numbers or names */}
              {selectedPhrase && selectedPhrase !== '' && selectedPhrase.toLowerCase().trim() !== selectedVocab.fr.toLowerCase().trim() &&
                <span className="french-word">{selectedPhrase}&nbsp;&nbsp;
                <button className="audio-button" onClick={() => playWordAudio(selectedPhrase, play, englishTopic)}> 
                  <img src="Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
                </button><br/></span>
              }

              {selectedVocab.fr && selectedVocab.fr !== 'Unknown' && selectedVocab.fr !== 'unknown' && <div>  {/* number or name */}
                <span className="french-word">{selectedVocab.fr}</span>
                <span className="ipa" onClick={() => handleIPAClick()} style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  {selectedVocab.ipa}
                </span>
                <button className="audio-button" onClick={() => playWordAudio(selectedVocab.fr, play, englishTopic)}>
                  <img src="/Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
                </button>
                <button className="favorite-button" onClick={() => toggleFavorite({ fr: selectedVocab.fr, en: selectedVocab.en })}>
                  {favorites.has(selectedVocab.fr) ? <img src="/Icons/heartfilled.svg" alt={translations[selectedLanguage].Liked} /> : <img src="/Icons/heart.svg" alt={translations[selectedLanguage].Unliked}/>}
                </button>
                <br/>
              </div>}     

              {selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id === 3 ? (
                <span onClick={() =>handleClick('Les verbes pronominaux')} style={{ fontWeight:'600', cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  [{showPartOfSpeech(3, selectedLanguage)}]<br/>
                </span>
              ) : (
                selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id === 16 ? (
                  <span onClick={() =>handleClick('mon / ton / son')} style={{ fontWeight:'600', cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                    [{showPartOfSpeech(16, selectedLanguage)}]<br/>
                  </span>
                ) : (
                  selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id !== 'Unknown' && selectedVocab.part_of_speech_id !== 'unknown' && selectedVocab.part_of_speech_id !== 17 && <span>[{showPartOfSpeech(selectedVocab.part_of_speech_id, selectedLanguage)}]<br/></span>         
                )
              )}  
              {selectedVocab.contraction && selectedVocab.contraction.trim() !== '' && (
                selectedVocab.contraction.includes('<br/>') ? (
                  <span>
                    {translations[selectedLanguage].Contraction}:&nbsp; 
                    {selectedVocab.contraction.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  <br/></span>
                ) : (
                  <span>{translations[selectedLanguage].Contraction}: {selectedVocab.contraction}<br/></span>
                )                
              )}
              {selectedVocab.masculine && selectedVocab.masculine.trim() !== '' && (
                <span>{translations[selectedLanguage].Masculine}: {selectedVocab.masculine}<br/></span>
              )}
              {selectedVocab.feminine && selectedVocab.feminine.trim() !== '' && (
                <span>{translations[selectedLanguage].Feminine}: {selectedVocab.feminine}<br/></span>
              )}
              {selectedVocab.plural && selectedVocab.plural.trim() !== '' && (
                <span>{translations[selectedLanguage].Plural}: {selectedVocab.plural}<br/></span>
              )}  
              {selectedVocab.grammar_topic && selectedVocab.grammar_topic.trim() !== '' && (
                <span>
                  {translations[selectedLanguage].GrammarTopic} 
                  {/* {['y / en', 'Les objets', 'Le passif', 'Le gérondif', 'me / te / le', 'Qui'].includes(selectedVocab.grammar_topic) ? ( */}
                  <span
                    style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}
                    onClick={() => handleClick(selectedVocab.grammar_topic)}
                  >
                    {selectedVocab.grammar_topic}
                  </span> <br/>
                </span>
              )}      
              {selectedVocab.note_en && selectedVocab.note_en.trim() !== '' && (<span>{translations[selectedLanguage].Note}{getNote(selectedVocab, selectedLanguage)}<br/></span>)}
              <span style={{marginTop:'10px', marginBottom:'10px'}} className="french-word">{getTranslatedSentence(selectedVocab, selectedLanguage)}</span>
              
              {(selectedVocab.part_of_speech_id === 2 || selectedVocab.part_of_speech_id === 3) && selectedVocab.tense_name_fr && (
                <div className="conjugation">
                  {!isObjectEmpty(selectedVocab.tense_type_fr) && selectedVocab.tense_type_fr !== '' && selectedVocab.tense_type_fr !== 'Indicatif' ? (
                    <span>
                      <br/>
                      <b onClick={() => handleClickVerbName()} style={{ cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                        {selectedVocab.tense_type_fr}{' - '}{selectedVocab.tense_name_fr}
                      </b>
                    </span>
                  ) : (
                    <span>
                      <br/>
                      <b onClick={() => handleClickVerbName()} style={{ cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                        {selectedVocab.tense_name_fr}
                      </b>
                    </span>
                  )}
                  <br/>
                  {selectedVocab.conjugation && selectedVocab.conjugation !== '' &&                
                    <span>{selectedVocab.conjugation.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>}
                  
                </div>
              )}

              {isPopupOpen && (
                <div className="popup-link">
                  <div className="popup-link-content">
                  <button onClick={backButtonPopupClick} className="back-button-popup"> <img src="/Icons/arrow-left-popup.svg" alt={translations[selectedLanguage].back} />  </button>
                  <button className="x-button-link" onClick={handleClose}><b>X</b></button>
                  {isIPAClick ? 
                  (<div><br/><span dangerouslySetInnerHTML={{ __html: ipaContent }} /> </div>)
                  :
                  (<div><h4>{currentTopicName}</h4> 
                  <br/>
                  <span dangerouslySetInnerHTML={{ __html: grammarContent }} /> </div>)
                  }
                  </div>
                </div>
              )}
            </div>
        
        ) :       
        (
          sentencesForStudyForSpeak[current] && (
            <div className="microphone" onClick={recording ? stopRecording : startRecordingSentence}>
              {recording ?         
                <button  className="recording-animation">
                  <img src="/Icons/stop.svg" alt={translations[selectedLanguage].Stop} /> 
                </button>          
              :
              <button ref={microphoneRef} className="microphone-button">
                <img src="/Icons/microphone.svg" alt={translations[selectedLanguage].Microphone} />
              </button>}
            </div>
          )          
        )        
        }  
        <div style={{marginTop:'0px', textAlign:'right'}}>
          <button className="next-button" style={{marginTop:'10px'}} onClick={() => goToNext()}>
            <b>{translations[selectedLanguage].next}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
          </button>  
          <br/><br/><br/><br/>
        </div>
                        
      </div>  
      <BottomMenuComponent steps={steps}/>
      </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseATheme}</button>        
        )}     
    </div>
  );
};

export default SpeakingSentences;