import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// TO COMMIT FROM A MICROSOFT PC TO LINUX SERVER (GITHUB CODE SHOULD BE LINUX SERVER)
// CHANGE axios.get('http://localhost:5001   TO  axios.get('/api
// AND UNCOMMENT THE BELOW CODE
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5001', // Use .env file for different environments
});

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [noviceThemes, setNoviceThemes] = useState(() => {
    const storedThemes = localStorage.getItem('noviceThemes');
    return storedThemes ? JSON.parse(storedThemes) : [];
  });
  
  const [themes, setThemes] = useState(() => {
    const storedThemes = localStorage.getItem('themes');
    return storedThemes ? JSON.parse(storedThemes) : [];
  });

  const [topics, setTopics] = useState(() => {
    const storedTopics = localStorage.getItem('topics');
    return storedTopics ? JSON.parse(storedTopics) : [];
  });

  const [allTopics, setAllTopics] = useState(() => {
    const storedAllTopics = localStorage.getItem('allTopics');
    return storedAllTopics ? JSON.parse(storedAllTopics) : [];
  });

  const [conversations, setConversations] = useState(() => {
    const storedConversations = localStorage.getItem('conversations');
    return storedConversations ? JSON.parse(storedConversations) : [];
  });

  const [sentencesForStudy, setSentencesForStudy] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForStudy');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });
  // a boolean array to distinguish speaking and listening sentences
  const [sentencesForStudyForSpeak, setSentencesForStudyForSpeak] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForStudyForSpeak');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });
  const [endWithAI, setEndWithAI] = useState(false);
  const [grammarGroups, setGrammarGroups] = useState([]);
  const [grammarTopics, setGrammarTopics] = useState([]);
  const [grammarContent, setGrammarContent] = useState([]);
  const [pronunciationGroups, setPronunciationGroups] = useState([]);
  const [pronunciationTopics, setPronunciationTopics] = useState([]);
  const [pronunciationContent, setPronunciationContent] = useState([]);
  const [myVocab, setMyVocab] = useState([]);

  useEffect(() => {
    localStorage.setItem('conversations', JSON.stringify(conversations));
  }, [conversations]);

  useEffect(() => {
    localStorage.setItem('sentencesForStudy', JSON.stringify(sentencesForStudy));
  }, [sentencesForStudy]);
  useEffect(() => {
    localStorage.setItem('sentencesForStudyForSpeak', JSON.stringify(sentencesForStudyForSpeak));
  }, [sentencesForStudyForSpeak]);
  
  useEffect(() => {
    localStorage.setItem('noviceThemes', JSON.stringify(noviceThemes));
  }, [noviceThemes]);
  
  useEffect(() => {
    localStorage.setItem('themes', JSON.stringify(themes));
  }, [themes]);

  useEffect(() => {
    localStorage.setItem('topics', JSON.stringify(topics));
  }, [topics]);

  useEffect(() => {
    localStorage.setItem('allTopics', JSON.stringify(allTopics));
  }, [allTopics]);

  const sendFeedback = async (message) => {
    try {
      const response = await axios.post('http://localhost:5005/submit-feedback', { message });
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const fetchNoviceThemes = async () => {
    try {
      const response = await axios.get('/api/noviceThemes');
      setNoviceThemes(response.data);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };
  
  const fetchThemes = async () => {
    try {
      const response = await axios.get('/api/themes');
      setThemes(response.data);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  const fetchSearchTheme = async (topic_name_en = '') => {
    try {
      const response = await axios.get('/api/searchTheme', {
        params: { topic_name_en },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  const fetchTopics = async (language = '', theme = '') => {
    try {
      const response = await axios.get('/api/topics', {
        params: { language, theme },
      });
      setTopics(response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchAllTopics = async () => {
    try {
      const response = await axios.get('/api/allTopics');
      setAllTopics(response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchConversations = async (language = '', program = '', topic = '') => {
    try {
      if(program === 'novice'){
        const response = await axios.get('/api/noviceSentences', {
          params: { language, topic },
        });
        setSentencesForStudy(response.data.sentencesForStudy);
        setSentencesForStudyForSpeak(response.data.sentencesForStudyForSpeak);
      } else if(program === 'general') {
        const response = await axios.get('/api/conversations', {
          params: { language, topic },
        });
        setConversations(response.data.conversations);
        setSentencesForStudy(response.data.sentencesForStudy);
        setSentencesForStudyForSpeak(response.data.sentencesForStudyForSpeak);
        setEndWithAI(response.data.endWithAI);
      }      
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const fetchUpdatedVocab = async (vocabTerm) => {
    try {
      const response = await axios.get('/api/updateVocab', {
        params: { vocabTerm },
      });  
      return response.data;
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };

  const fetchMyVocab = async (favoriteArray) => {
    try {
      const response = await axios.get('/api/myVocab', {
        params: { favoriteArray },
      });
      setMyVocab(response.data);
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };
  
  const fetchGrammarGroups = async () => {
    const response = await axios.get('/api/grammar-groups');
    setGrammarGroups(response.data);
  };

  const fetchGrammarTopics = async (groupName = '') => {
    const response = await axios.get('/api/grammar-topics/', {
      params: { groupName },
    });
    setGrammarTopics(response.data);
  };
    
  const fetchGrammarContent = async (topicName = '', selectedLanguage = '') => {
    const response = await axios.get('/api/grammar-content', {
      params: { topicName,  selectedLanguage },
    });
    setGrammarContent(response.data);
  };

  const fetchPronunciationGroups = async () => {
    const response = await axios.get('/api/pronunciation-groups');
    setPronunciationGroups(response.data);
  };

  const fetchPronunciationTopics = async (groupId = 0) => {
    const response = await axios.get('/api/pronunciation-topics/', {
      params: { groupId },
    });
    setPronunciationTopics(response.data);
  };
    
  const fetchPronunciationContentFromTopic = async (topicId = 0, selectedLanguage = '') => {
    const response = await axios.get('/api/pronunciation-content-from-topic', {
      params: { topicId, selectedLanguage },
    });
    setPronunciationContent(response.data);
  };

  const fetchPronunciationContentFromGroup = async (groupId = 0, selectedLanguage='') => {
    const response = await axios.get('/api/pronunciation-content-from-group', {
      params: { groupId, selectedLanguage },
    });
    setPronunciationContent(response.data);
  };

  return (
    <DataContext.Provider
      value={{ sendFeedback, fetchNoviceThemes, noviceThemes, themes, fetchThemes, topics, fetchTopics, sentencesForStudy, sentencesForStudyForSpeak, conversations, endWithAI, fetchConversations, fetchUpdatedVocab, grammarGroups, fetchGrammarGroups, grammarTopics, fetchGrammarTopics, grammarContent, setGrammarContent, fetchGrammarContent, pronunciationGroups, fetchPronunciationGroups, pronunciationTopics, fetchPronunciationTopics, pronunciationContent, setPronunciationContent, fetchPronunciationContentFromTopic, fetchPronunciationContentFromGroup, fetchSearchTheme, fetchAllTopics, allTopics, setSentencesForStudy, setSentencesForStudyForSpeak,  setConversations, setTopics, myVocab, fetchMyVocab, setGrammarTopics, setPronunciationTopics }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
